<script>
// import Layout from "../../../../layouts/main";
// import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
 import { required } from "vuelidate/lib/validators";
// import { fetchSettings } from "../api";
// import infoStaff from './infoStaff.vue';
// import Infrastructure from './infrastructure.vue';
// import Multiselect from 'vue-multiselect'
import Swal from "sweetalert2";
import { getDropDownElementsByTypeApi} from "@/api/common";
// import SideButtons from '../../../../../../components/side-buttons.vue';


export default {

    page: {
        title: "",
        meta: [{ name: "description", content: appConfig.description}],
    },
    components: {/* SideButtons */ },
    props:['selectedVisitor','visitor'],
    data() {
      return {
      tableData: [],
      title: "Nouveau visiteur",
      form: {
        first_name:'',
        last_name:'',
        employee_number : '',
        n_cin:'',
        post:'',
        structure_id:'',
        sexe:'',
        nationality :''
      },
       sexes:[
            {text:'Homme',value:1},
            {text:'Femme',value:0}
        ],
      submitted: false,
      submitform: false,
      submit: false,
      typesubmit: false,
      companys:[],
      nationalitys:[],
      
      disabledAdd   : false,
      disabledEdit  : true,
      disabledDelete: true,
      disabled      : true,
      disabledCancel: true,
      lastSelected  : null,
      add : false,
      edit : false,
      destroy : false,
      send : false,
      have : false,
    };
  },
  validations: {
      form:{
        first_name :{ required },
        last_name:{ required },
        employee_number:{ required },
        n_cin:{ required },
        post:{ required },
        structure_id:{ required },
        sexe:{ required },
        nationality:{ required },
      }
  },
  watch:{
    
    add(){
      this.addBtn()
    },
    edit(){
      this.editBtn()
    },
    destroy(){
      this.deleteEmp()
    },
    
  selectedVisitor(){
        if(this.selectedVisitor.length != 0){
          this.addSelectedRow()         
        }else{
          this.disabled       = true;
          this.disabledAdd    = false;
          this.disabledDelete = true;
          this.disabledEdit   = true;
          this.disabledCancel = true;
          this.clearForm();

        }
      } 
    },
  mounted(){
    this.getnationalitys();
    this.getComp();
  },

 methods:{
    addSelectedRow(){
      this.lastSelected   = this.selectedVisitor;
      this.disabled       = true;
      this.disabledAdd    = false;
      this.disabledDelete = false;
      this.disabledEdit   = false;
      this.disabledCancel = true;
      this.form.uuid            = this.selectedVisitor.uuid;
      this.form.last_name       = this.selectedVisitor.last_name;
      this.form.first_name      = this.selectedVisitor.first_name;
      this.form.employee_number = this.selectedVisitor.employee_number;
      this.form.n_cin           = this.selectedVisitor.n_cin;
      this.form.post            = this.selectedVisitor.post;
      this.form.sexe            = this.selectedVisitor.sexe;
      this.form.structure_id    = this.selectedVisitor.structure_id;  
      this.form.nationality     = this.selectedVisitor.nationality;    

   },
   addBtn(){
      this.disabled       = false;
      this.disabledAdd    = true;
      this.disabledEdit   = true;
      this.disabledCancel = false;
      this.clearForm();
    },
    editBtn(){
      this.disabledAdd    = false;
      this.disabledEdit   = true;
      this.disabled       = false;
      this.disabledCancel = false;
    },
    cancelBtn(){
      this.disabled       = true;
      this.disabledAdd    = false;
      
      if(this.lastSelected != null)
      this.addSelectedRow(this.lastSelected);

    },
     getComp(){
         this.$http.post('/referentiel/intra_entreprise/list')
            .then((res) => {
                this.companys = res.data.original.list;
            }).catch((error) => {
            this.$toast.error(error.message);
            })
            .finally(() => {});
    },
    clearForm(){
        this.form.uuid            = "";
        this.form.first_name      = "";
        this.form.last_name       = "";
        this.form.employee_number = "";
        this.form.n_cin           = "";
        this.form.post            = "";
        this.form.structure_id    = "";
        this.form.nationality     = "";
        this.form.sexe            = "";
           
    },
    getnationalitys(){
         getDropDownElementsByTypeApi('country')
          .then((res) => {
              this.nationalitys = res.data.list;
            })
            .catch(() => {
              // error.response.status Check status code
            })
            .finally(() => {
              //Perform action in always
            });
     },
     formSubmit() {

      this.submitted = true;
      var valid = this.$v.form.$invalid;
      this.$v.$touch();

      if(!valid){
      this.submitted = false;

      this.$http
        .post("/referentiel/employee_intra/store", this.form)

        .then((res) => {
          var status = res.data.original.status;
          switch (status) {
            case 200:
              this.$toast.success(res.data.original.msg);
              // Swal.fire("Félicitations!", res.data.original.msg, "success");
              this.$emit('refrechTable', true);
              this.cancelBtn();
              this.clearForm();
              break;

            case 500:
              Swal.fire("Avertissement!", res.data.original.msg, "warning");
              break;
          }
        })
        .catch((error) => {
          Swal.fire("Erreur!", error.message, "error");
        })
        .finally(() => {});
      }

    },
    deleteVisitor() {
      var _this = this; 
      var typeUid  = this.form.uuid
      var typeName = this.form.last_name
      Swal.fire({
        title: "Êtes-vous sûr de supprimer  l'Employé  : " + typeName + "?",
        text: "Vous ne pourrez pas annuler cela!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#f46a6a",
        cancelButtonColor: "#B8CCD7",
        cancelButtonText: "Annuler",
        confirmButtonText: "Oui!",
      }).then((result) => {
        if (result.value) {
          this.$http
            .post("/referentiel/employee_intra/delete/" + typeUid)
            .then((res) => {
              var status = res.data.original.status;
              switch (status) {
                case 200:
                  this.$toast.success(res.data.original.msg);
                  this.lastSelected   = null;
                  this.$emit('refrechTable', true);
                  this.clearForm();
                  break;

                case 500:
                  this.$toast.warning(res.data.original.msg);
                  break;
              }
             
            })
            .catch((error) => {
              this.$toast.error(error.message);
            })
            .finally(() => {});
        }
      });
    },
  }
    
}
</script>
<style>
  #StickyEF {
    position: fixed;
    right: 0;
    top: 50%;
    width: 8em;
    margin-top: -2.5em;
    z-index: 999;
    list-style: none;
  }
  .rounded-pillEF{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    border-radius: 50% !important;
  }
  .btn-addEF{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    background-color: #044A72 ;
  }
  .btn-duplicateEF{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    background-color: #8FC412 ;
  }
  .btn-editEF{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    background-color: #FFB236 ;
  }
  .btn-deleteEF #dropdown-dropleft__BV_toggle_{
     border-radius: 50% !important;
  }
  .btn-deleteEF{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    background-color: #D61337;
  }
  .btn-uploadEF{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    background-color: #2A5769 ;
  }
  .btn-downloadEF{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    background-color: #21BF92;
  }
  .btn-cancelEF{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    background-color: #B8CCD7 ;
  }
.form-check-input{
  font-size: 24px;
}
</style>
<template>
    <!-- <Layout>
    <PageHeader :title="title" :items="items" /> -->
    <div class="row">
      <div class="col-12">
        <div class="card-body">
          <!-- <SideButtons
            :select="disabledAdd"  :canEdit="disabledEdit" :save="send" :ODS="false"   @add="add = $event" @edit=" edit = $event"  @delete=" destroy = $event" />
           --><ul id="StickyEF" >
            <li>
              <button type="button" @click="addBtn" :disabled="disabledAdd" class="btn btn-addEF rounded-pillEF float-right mr-1 mt-1">
              <i class="fas fa-plus text-white"></i>
              </button>
            </li>
            <li>
              <button type="button" @click="editBtn" :disabled="disabledEdit" class="btn btn-editEF rounded-pillEF float-right mr-1 mt-1">
                <i class="fas fa-pencil-alt text-white" style=""></i>
              </button>
            </li>
            <li>
              <button type="button" :disabled="disabledEdit" @click="deleteVisitor" class="btn btn-deleteEF rounded-pillEF float-right mr-1 mt-1">
                <i class="fas fa-trash-alt text-white" style=""></i>
              </button>
            </li>
          </ul>
          <h4 class="card-title">Details </h4>
          <fieldset :disabled="disabled">
            <form class="needs-validation" @submit.prevent="formSubmit" enctype="multipart/form-data">
              <div class="row">
                <div class="col-xs-12 col-md-4 col-lg-3">
                  <div class="form-group">
                    <label for="formrow-password-input">Structures</label>
                    
                    <select class="form-control" v-model="form.structure_id" 
                    
                    :class="{
                      'is-invalid': submitted && $v.form.structure_id.$error,
                    }">
                        <option selected disabled>Selectionner une entreprise</option>
                        <option v-for="company in this.companys" :key="company.id" :value="company.id"> {{company.intra_entreprises_name}} </option>
                    </select>
                    <div
                        v-if="submitted && $v.form.structure_id.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.form.structure_id.required"
                          >Le champ est obligatoire.</span
                        >

                      </div>
                  </div>
                </div>
                <div class="col-xs-12 col-md-4 col-lg-3">
                      <div class="form-group">
                        <label for="formrow-inputCity">Matricule *</label>
                        <input type="text"  class="form-control" v-model="form.employee_number" 
                          
                          :class="{ 'is-invalid': submitted && $v.form.employee_number.$error,}" placeholder=""/>
                          <div v-if="submitted && $v.form.employee_number.$error" class="invalid-feedback">
                            <span v-if="!$v.form.employee_number.required">Le champ est obligatoire.</span>
                          </div>
                      </div>
                    </div>
                <div class="col-xs-12 col-md-4 col-lg-3">
                  <div class="form-group">
                    <label for="formrow-inputCity">Nom  *</label>
    
                    <input type="text"  class="form-control" v-model="form.first_name" 
                      
                    :class="{
                      'is-invalid': submitted && $v.form.first_name.$error,
                    }" placeholder=""
                    />
                      <div
                        v-if="submitted && $v.form.first_name.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.form.first_name.required"
                          >Le champ est obligatoire.</span
                        >
                      </div>
                  </div>
                </div>
                <div class="col-xs-12 col-md-4 col-lg-3">
                  <div class="form-group">
                    <label for="formrow-inputCity">Prénom *</label>
    
                    <input type="text"  class="form-control" v-model="form.last_name" 
                      
                    :class="{
                      'is-invalid': submitted && $v.form.last_name.$error,
                    }" placeholder=""
                    />
                      <div
                        v-if="submitted && $v.form.last_name.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.form.last_name.required"
                          >Le champ est obligatoire.</span
                        >
                      </div>
                  </div>
                </div>
                    <div class="col-xs-12 col-md-4 col-lg-3">
                      <div class="form-group ">
                        <label for="formrow-inputCity">Sexe * </label>
                          <select class="form-control" v-model="form.sexe" 
                        
                        :class="{ 
                          'is-invalid': submitted && $v.form.sexe.$error,
                        }">
                            <option v-for="s in sexes" :key="s.text" :value="s.text"> {{s.text}} </option>
                        </select>
                          <div
                            v-if="submitted && $v.form.sexe.$error"
                            class="invalid-feedback"
                          >
                            <span v-if="!$v.form.sexe.required"
                              >Le champ est obligatoire.</span>
                          </div>

                      </div>
                    </div>

                <div class="col-xs-12 col-md-4 col-lg-3">
                  
                  <div class="form-group">
                    <label for="formrow-inputCity"
                      >Numéro de carte d'identitie *</label
                    >
                    <input type="text"  class="form-control" v-model="form.n_cin" 
                      
                    :class="{
                      'is-invalid': submitted && $v.form.n_cin.$error,
                    }" placeholder=""
                    />
                    <div
                        v-if="submitted && $v.form.n_cin.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.form.n_cin.required"
                          >Le champ est obligatoire.</span
                        >
                      </div>
                  </div>
                </div>
                
                <div class="col-xs-12 col-md-4 col-lg-3">
                  <div class="form-group">
                    <label for="formrow-email-input">Nationalité *</label>
                    <select v-model="form.nationality" class="form-control"
                      :class="{
                        'is-invalid': submitted && $v.form.nationality.$error,
                        'isDisabled disabledBG':disabled
                      }">
                      <option v-for="nat in nationalitys" :key="nat.uuid" :value="nat.uuid"> {{nat.designation}} </option>
                    </select>
                    
                    <div v-if="submitted && $v.form.nationality.$error"
                      class="invalid-feedback">
                      <span v-if="!$v.form.nationality.required">
                        Le champ est obligatoire.</span>

                    </div>
                  </div>
                </div>
                <div class="col-xs-12 col-md-4 col-lg-3">
                  <div class="form-group">
                    <label for="formrow-password-input">Fonction</label>
                    <input type="text"  class="form-control" v-model="form.post" placeholder=""
                      
                    :class="{
                      'is-invalid': submitted && $v.form.post.$error,
                    }">
                    <div
                        v-if="submitted && $v.form.post.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.form.post.required"
                          >La champ est obligatoire.</span
                        >

                      </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12" v-show="!disabled">
                  <button type="button"  @click="cancelBtn" class="btn btn-light btn-label float-right ml-2">
                    <i class="fas fa-times-circle text-danger label-icon"></i> Annuler
                  </button>
                  <button type="submit" class="btn btn-label btn-primary float-right" >
                    <i class="far fa-save label-icon "></i> Enregistrer
                  </button>
                </div>
              </div> 
            </form>
          </fieldset>
        </div>
      </div>
    </div>
    <!-- </Layout> -->
</template>
<style>
.form-check-input{
  font-size: 24px;
}
</style>